import React, { FC } from 'react';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import { useMediaQuery } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

import Layout from 'src/template/layout';
import Masonry from 'src/components/Masonry';
import SEO from 'src/components/SEO';

const Section = styled('section')({
  backgroundImage: 'url("/backgrounds/img-bg-type-01.svg")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
});

const TitleWrapper = styled('div')({
  marginBottom: 75,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Heading = styled(Typography)({
  fontSize: 72,
  marginBottom: 0,
}) satisfies typeof Typography;

const LinkMenuWrapper = styled('ul')({
  listStyle: 'none',
});

const Dot = styled('div')({
  width: 16,
  height: 16,
  margin: ' 0 0 0 59px',
  backgroundColor: '#00dd99',
  borderRadius: '50%',
});

const DotMenu = styled(Typography)({
  fontSize: 32,
  marginBottom: 32,
}) satisfies typeof Typography;

const ExternalLink = styled('a')({
  color: 'rgba(0, 0, 0, 0.2)',
  fontSize: 32,
  textDecoration: 'none',
});

const ExternalLinkText = styled(Typography)({
  color: 'rgba(0, 0, 0, 0.2)',
  fontSize: 32,
  textDecoration: 'none',
}) as typeof Typography;

const StyledLink = styled(Link)({
  textDecoration: 'none',
});

const BlogCard = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  marginBottom: 64,

  '&> img': {
    width: '100%',
    marginBottom: 16,
  },
  '& h3': {
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: 24,
    fontWeight: 'bold',
  },
}));

const Description = styled(Typography)({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  color: '#4a4a4a',
  fontSize: '1.25rem',
}) satisfies typeof Typography;

const DateText = styled(Typography)({
  color: '#858585',
  fontSize: '1rem',
}) satisfies typeof Typography;

// markup
const BlogPage: FC = () => {
  const ghost = useStaticQuery(graphql`
    query {
      allGhostPost(
        filter: { tags: { elemMatch: { name: { eq: "BLOG" } } } }
        sort: { order: DESC, fields: [published_at] }
      ) {
        nodes {
          id
          title
          feature_image
          published_at(formatString: "YYYY.MM.DD")
          plaintext
          ghostId
        }
      }
    }
  `);

  const postList = ghost.allGhostPost.nodes;

  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Layout>
      <Section>
        <Container>
          <TitleWrapper>
            <Heading variant="h1">WHAT’S NEW?</Heading>
            {!mdDown && (
              <LinkMenuWrapper>
                <li>
                  <Dot />
                  <DotMenu variant="h2">Culture</DotMenu>
                </li>
                <li>
                  <ExternalLink
                    href="https://hyperconnect.github.io/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ExternalLinkText component="h2">Tech</ExternalLinkText>
                  </ExternalLink>
                </li>
              </LinkMenuWrapper>
            )}
          </TitleWrapper>

          <Grid>
            <Grid item xs={12} md={9}>
              <Masonry>
                {postList.map(item => (
                  <StyledLink to={`/post/` + item.ghostId} key={item.ghostId}>
                    <BlogCard>
                      <img src={item.feature_image} alt={`${item.title}`} />
                      <Typography variant="h3">{item.title}</Typography>
                      <Description variant="body1">
                        {item.plaintext} <br />
                      </Description>
                      <DateText variant="body1">{item.published_at}</DateText>
                    </BlogCard>
                  </StyledLink>
                ))}
              </Masonry>
            </Grid>
            <Grid item md={3}></Grid>
          </Grid>
        </Container>
      </Section>
    </Layout>
  );
};

export default BlogPage;


export const Head = () => {
  return <SEO title={'blog'} />;
}